import { Link } from "react-router-dom";

const JobsFrontOffice = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Front Office Assistant</span>
    </div>

    <h1>Front Office Assistant</h1>

    <div class="wpb_wrapper">

<strong><font color="#2272FF">Position Summary:</font></strong> We are looking for a skilled front office assistant to manage diverse tasks in a multicultural environment. This role requires excellent organizational skills, adaptability, and strong communication abilities in English. The ideal candidate will have at least two years of relevant experience, no degree required.
 
<p></p>
<strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li><strong>Communication & Coordination</strong>: Handle emails and schedule meetings with clients, employees, prospects, and vendors across various time zones.</li>
        <li><strong>Travel Management</strong>: Arrange corporate travel, ensuring seamless logistics.</li>
        <li><strong>Supplies & Logistics</strong>: Order company supplies, print materials and manage the organization of receipts.</li>
        <li><strong>Event Planning</strong>: Coordinate workshops, Coffee Talks, IT English Club, Tea Time, and annual company retreat, including venue booking, vendor communication, setup/teardown, and logistic planning.</li>
        <li><strong>Calendar Management</strong>: Maintain corporate calendars to prevent missed meetings, events and deadlines.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>At least two years of relevant administrative experience.</li>
        <li>Strong attention to detail and observation skills.</li>
        <li>Ability to work independently while taking direction effectively.</li>
        <li>Cultural adaptability and eagerness to learn new ways in a diverse environment.</li>
        <li>Proficient English communication skills (both written and verbal).</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Compensation & Benefits</font></strong><p></p>
    <ul>
        <li>Salary will be discussed based on candidates' experience and ability. 100% salary during probation.</li>
        <li>Collaborative and innovative work environment.</li>
        <li>Opportunity for growth and training.</li>
        <li>Opportunities to work with global customers from different cultures.</li>
        <li>Experience an international business environment, which provides opportunities to improve Business English.</li>
        <li>Company Retreat once a year for full-time employees.</li>
        <li>Experience the unique harmony of Vietnamese - American Culture.</li>
        <li>Tea-times, Coffee Talk, I.T. English Club, sports activities, in-house games, etc.</li>
        <li>Working time: Monday - Friday, 8:30AM - 6:00PM.</li>
        <li>Annual Salary review and performance bonus.</li>
        <li>12 days annual leave per year.</li>
        <li>Company-provided computer, iPad, camera, and earbuds as needed for working at the office.</li>
        <li>Insurance provided in full compliance with the Vietnam Labor Code.</li>
    </ul>
<p></p>

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  export default JobsFrontOffice;