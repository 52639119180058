import { Link } from "react-router-dom";

const JobsMarketingDesignIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Marketing Design Intern</span>
    </div>

    <h1>Marketing Design Intern</h1>

    <div class="wpb_wrapper">
    <strong><font color="#2272FF">Job Overview:</font></strong> Saigon A.I. is seeking a motivated and enthusiastic Marketing Design Intern to join our dynamic team on a part-time basis. This internship offers an excellent opportunity for recent graduates or current students to gain hands-on experience in marketing and design within the tech industry. The ideal candidate will be eager to learn, highly creative, and have strong communication skills.


 <p></p>
 <strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li><strong>Design Support:</strong> Assist the Marketing Team with designing visually appealing marketing materials such as brochures, banners, social media posts, website, infographics, and presentations.</li>
        <li><strong>Campaign Assistance:</strong> Help in conceptualizing and executing marketing campaigns by contributing ideas, creating assets, and supporting campaign rollouts.</li>
        <li><strong>Creative Collaboration:</strong> Brainstorm creative concepts for campaigns, promotional activities, and brand initiatives under the guidance of the Marketing Team.</li>
        <li><strong>Social Media Management:</strong> Support in managing social media platforms by designing posts, scheduling content, and analyzing performance metrics.</li>
        <li><strong>Brand Guidelines:</strong> Ensure that all marketing materials adhere to the company’s branding guidelines and maintain consistency across all channels.</li>
        <li><strong>Team Collaboration:</strong> Work closely with other teams to gather design requirements and deliverables. Participate in brainstorming sessions and provide design inputs as needed.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>Currently enrolled in a Bachelor's degree program in Marketing, Graphic Design, Communications, or a related field.</li>
        <li>Proficiency in design software such as Adobe Creative Suite (Photoshop, Illustrator), Canva, or Figma.</li>
        <li>Strong understanding of branding principles and visual communication.</li>
        <li>Excellent communication skills with the ability to translate ideas into creative designs.</li>
        <li>A keen eye for detail and strong organizational skills to manage multiple projects effectively.</li>
        <li>Demonstrated ability to work well in a team setting and adapt to changing priorities.</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">A Plus:</font></strong><p></p>
    <ul>
        <li>Familiarity with SEO best practices and social media analytics tools.</li>
        <li>Basic knowledge of video editing software (e.g., Adobe Premiere, Final Cut Pro).</li>
        <li>Experience with motion graphics or animation tools like After Effects would be an advantage.</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">What We Offer:</font></strong><p></p>
    <ul>
        <li>Hands-on experience in creating impactful marketing materials and campaigns.</li>
        <li>Mentorship from experienced marketers.</li>
        <li>A supportive and innovative work environment.</li>
        <li>Flexible working hours to accommodate academic commitments.</li>
    </ul>
    <p></p>
 <strong><font color="#2272FF">Working Hours:</font></strong><p></p>
    Monday - Friday between 8:30am - 6:00pm, flexible hours to be determined based on availability. Part-time commitment required.

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsMarketingDesignIntern;