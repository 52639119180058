import { Link } from "react-router-dom";

const JobsProjectCoordinatorIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Project Coordinator Intern</span>
    </div>

    <h1>Project Coordinator Intern</h1>

    <div class="wpb_wrapper">
    <strong><font color="#2272FF">Job Overview:</font></strong> Saigon A.I. is seeking a motivated and enthusiastic Project Coordinator Intern to join our dynamic team on a part-time basis. This internship offers an excellent opportunity for recent graduates or current students to gain hands-on experience in project coordination within the tech industry. The ideal candidate will be eager to learn, highly organized, and have excellent communication skills.


 <p></p>
 <strong><font color="#2272FF">Key Responsibilities:</font></strong><p></p>
    <ul>
        <li><strong>Support Project Management:</strong> Assist the Project Manager with various tasks, including planning, tracking, and coordinating project activities.</li>
        <li><strong>Documentation and Software Maintenance:</strong> Help maintain and update project management software and documentation under guidance.</li>
        <li><strong>Scheduling and Resource Allocation:</strong> Support in preparing project schedules and resource allocation as directed.</li>
        <li><strong>Reporting and Updates:</strong> Assist in preparing and distributing project reports and status updates.</li>
        <li><strong>Meeting Coordination:</strong> Participate in meetings, take minutes, and follow up on action items.</li>
        <li><strong>Team Collaboration:</strong> Support team collaboration and ensure efficient workflow by assisting with internal communications.</li>
    </ul>
<p></p>
<strong><font color="#2272FF">Qualifications:</font></strong><p></p>
    <ul>
        <li>Currently enrolled in a Bachelor's degree program in Business Administration, Project Management, or a related field.</li>
        <li>Strong organizational skills and attention to detail.</li>
        <li>Excellent communication skills.</li>
        <li>Demonstrated ability to work well with others in a team setting.</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">A Plus:</font></strong><p></p>
    <ul>
        <li>Familiarity with Agile methodology.</li>
        <li>Basic understanding of software development processes and IT/AI terminology.</li>
    </ul>

 <p></p>
 <strong><font color="#2272FF">What We Offer:</font></strong><p></p>
    <ul>
        <li>Hands-on experience in creating impactful marketing materials and campaigns.</li>
        <li>Mentorship from experienced project managers.</li>
        <li>A supportive and innovative work environment.</li>
        <li>Flexible working hours to accommodate academic commitments.</li>
    </ul>
    <p></p>
 <strong><font color="#2272FF">Working Hours:</font></strong><p></p>
    Monday - Friday between 8:30am - 6:00pm, flexible hours to be determined based on availability. Part-time commitment required.

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>

<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsProjectCoordinatorIntern;