import { Link } from "react-router-dom";

const JobsDataAnalystIntern = () => {
    return (
    <>

    <div class="breadcrumb">
        <span class="caret">&gt;</span>
        <span class="link"><Link to="/jobs">Work Opportunities</Link></span>
        <span class="caret">&gt;</span>
        <span class="current">Data Science Intern</span>
    </div>

    <h1>Data Science Intern</h1>

    <div class="wpb_wrapper">

        
    <strong><font color="#2272FF">Job Description:</font></strong> Saigon A.I. is seeking a motivated and enthusiastic Data Science Intern to join our dynamic team on a part-time basis. This internship offers an excellent opportunity for recent graduates or current students to gain hands-on experience in marketing and design within the tech industry. The ideal candidate will be eager to learn, highly creative, and have strong communication skills.
    <p></p>
    <strong><font color="#2272FF">Key Responsibilities:</font></strong>
    <p></p>
    <ul>
        <li><strong>Data Management:</strong> Assist in collecting, cleaning, preparing, and transforming raw data into structured formats suitable for advanced analysis and machine learning models.</li>
        <li><strong>Exploratory Data Analysis (EDA):</strong> Uncover patterns, trends, and insights through EDA, contributing to the enhancement of AI-driven solutions by preparing datasets for model training.</li>
        <li><strong>Dashboard and Report Development:</strong> Create visually compelling dashboards and reports to communicate data-driven insights effectively, aiding in strategic decision-making.</li>
        <li><strong>Documentation:</strong> Maintain clear, reproducible documentation of processes, methodologies, and findings to ensure project transparency and scalability.</li>
        <li><strong>Continuous Learning:</strong> Engage in self-directed learning to deepen your understanding of data science principles, algorithms, and industry best practices.</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">Qualifications:</font></strong>
    <p></p>
    <ul>
        <li>Currently pursuing or recently completed a degree in Data Science, Statistics, Mathematics, Computer Science, Economics, International Business, or a related field.</li>
        <li>Strong analytical thinking and problem-solving skills with an eye for detail and a passion for continuous learning.</li>
        <li>Ability to work independently and collaboratively within a dynamic team environment.</li>
        <li>Experience in data cleaning, preprocessing, transformation techniques, and feature engineering.</li>
        <li>Familiarity with machine learning concepts, algorithms, and frameworks (e.g., Python libraries like scikit-learn, TensorFlow).</li>
        <li>Understanding of statistical analysis, hypothesis testing, and experimental design.</li>
        <li>Proficiency in programming languages such as Python, along with tools for data visualization (e.g., Tableau, Power BI, MatPlotLib, eCharts) and version control (e.g., Git).</li>
</ul>
    <p></p>
    <strong><font color="#2272FF">A Plus:</font></strong>
    <p></p>
    <ul>
        <li>Familiarity with automations, LLMs, or data engineering</li>
        <li>Familiarity with the math behind the algorithms.</li>
        <li>Familiarity in how to write an algorithm from scratch (without packages)</li>
    </ul>
    <p></p>
    <strong><font color="#2272FF">What We Offer:</font></strong>
    <p></p>
    <ul>
        <li>Hands-on experience with real-world data projects.</li>
        <li>Mentorship from experienced data scientists and analysts.</li>
        <li>A supportive and innovative work environment.</li>
        <li>Flexible working hours to accommodate academic commitments.</li>
</ul>
<p></p>
 <strong><font color="#2272FF">Working Hours:</font></strong><p></p>
    Monday - Friday between 8:30am - 6:00pm, flexible hours to be determined based on availability. Part-time commitment required. 

<div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
<span class="vc_sep_holder vc_sep_holder_l">
    <span class="vc_sep_line"></span>
</span>
<span class="vc_sep_holder vc_sep_holder_r">
    <span class="vc_sep_line"></span>
</span>
</div>
</div>
<Link to="/jobs/process" class="btn btn-warning">How To Apply</Link>


    </>
    )
  };
  
  export default JobsDataAnalystIntern;